<template>
  <div>
    <v-form @submit.prevent="upload">
      <v-card>
        <v-card-title>{{ __('Upload do arquivo', 'new_lead') }}</v-card-title>
        <ValidationObserver ref="importLeadForm">
          <v-card-text>
            <ValidationProvider rules="required" name="external_reference" v-slot="{ errors }">
              <v-text-field
                v-model="external_reference"
                :label="__('Número da companhia', 'new_lead')"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider rules="required" name="file" v-slot="{ errors }">
              <v-file-input 
                v-model="file"
                :label="__('Arquivo', 'new_lead')"
                :error-messages="errors"
                @change="getTempFile"
              ></v-file-input>
            </ValidationProvider>

            <template v-if="preview_file.url">
              <v-card-title class="pl-0">
                {{ __('Prévia', 'new_lead') }} ({{ preview_file.name }})
              </v-card-title>
              <iframe
                :src="getFilePublicUrl()"
                frameborder="0"
                width="100%"
                height="500px"
                style="background-color: white"
              ></iframe>
            </template>
          </v-card-text>
        </ValidationObserver>
      </v-card>

      <!-- Action Bar -->
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title> </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="$router.go(-1)" class="mr-2" elevation="0">
                {{ __('Cancelar', 'leads') }}
              </v-btn>
              <v-btn type="submit" color="primary">{{ __('Importar', 'leads') }}</v-btn>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="warning_dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <v-icon left color="warning">mdi-alert</v-icon> {{ __('Avisos', 'new_lead') }}
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(warning, index) in warnings" :key="'warning-' + index">{{ warning }}</li>
          </ul>
        </v-card-text>
        <v-card-text class="font-weight-medium ml-6">
          {{ __('Deseja continuar mesmo assim?', 'new_lead') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="warning_dialog = false">
            {{ __('Não', 'leads') }}
          </v-btn>
          <v-btn color="primary" text @click="continueWithWarning">
            {{ __('Sim', 'leads') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getTempFile } from '@/services/files';
import i18n from '@/mixins/i18n'; 

export default {
  mixins: [i18n],
  data() {
    return {
      external_reference: '',
      file: null,
      importNotFound: false,
      preview_file: {
        url: null,
        name: null,
        extension: null
      },
      warning_dialog: false
    }
  },

  computed: {
    warnings() {
      return this.$store.getters['lead_form/getWarnings'];
    }
  },

  methods: {
    async getTempFile() {
      const response = await getTempFile(this.file, 'import_lead');
      this.preview_file.url = response.url; 
      this.preview_file.name = response.name; 
      this.preview_file.extension = response.extension;
    },
    getFilePublicUrl() {
      switch (this.preview_file.extension) {
        case "xlsx":
        case "pptx":
        case "docx":
          return this.microsoft_preview(this.preview_file.url);
        default:
          return this.preview_file.url;
      }
    },
    continueWithWarning() {
      const insurerId = this.$route.params.insurer_id;
      this.$router.push({
          name: 'lead_new_import_form',
          params: {
              insurer_id: insurerId
          }
      });
    },
    async upload() {
      const validation = await this.$refs.importLeadForm.validate();
      if (!validation) return;
  
      const insurerId = this.$route.params.insurer_id;

      const formData = new FormData();
      formData.append('external_reference', this.external_reference);
      formData.append('file', this.file);
        
      this.$store.commit('setLoading', {
          loading: true
      });

      try {
        await this.$store.dispatch('lead_form/importLeadMetadata', {
          formData,
          insurerId
        });

        if (this.warnings.length > 0) {
          this.warning_dialog = true;
        } else {
          this.$router.push({
              name: 'lead_new_import_form',
              params: {
                  insurer_id: insurerId
              }
          });
        }
      } catch(error) {
          if(error.response.status == 404) {
              this.importNotFound = true;
          }
          this.$store.commit('sendMessage', {
              text: this.__('Erro ao importar arquivo', 'new_lead'),
              color: 'error'
          });
      } finally {
          this.$store.commit('setLoading', {
              loading: false
          });
      }
    },

    microsoft_preview(public_url) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`;
    },
  },
}
</script>

<style>

</style>
import Vue from "vue";


export async function getTempFile(file, type) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  const response = await Vue.prototype.$http.post("/files/temp", formData);

  return response.data;
}
